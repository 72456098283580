<template>
  <div class="landingPage">
    <div v-if="code === '200'">
      <p>{{message}}</p>
      <p>Wir werden uns schnellstmöglich bei dir melden. <span class="smiley"></span></p>
    </div>

    <div v-else-if="code === '406'">
      <!--  TODO: Message evtl. anpassen, Fehlercodes erweitern    -->
      <p>{{message}}Leider ist ein Fehler aufgetreten</p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    code: String,
    message: String,
  }
}

</script>

<style lang="scss" scoped>

@import "@/assets/style/variablen";

.landingPage {
  background-color: $nav;
  width: 80%;
  color: $element;
  margin: 50px auto 0 auto;
  padding: 2em;
  border-radius: $border-radius-content;
}

.smiley {
  background: url("../../assets/svg/nerd-face.svg") no-repeat;
  display: inline-block;
  height: 1em;
  width: 1em;
}
</style>