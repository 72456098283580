<template>
  <div class="footer">
    <ul>
      <li>
        <RouterLink to="/impressum">Impressum & Datenschutz</RouterLink>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
}

</script>

<style lang="scss" scoped>

@import "@/assets/style/variablen";

  a {
    color: $nav;
    cursor: pointer;
    font-size: 0.8em;
    opacity: .6;
    text-decoration: none;
  }

  .footer {
    width: 100vw;
    height: 1.5em;
    display: flex;
    position: fixed;
    z-index: 10000;
    background-color: $body-bg;
    box-shadow: 0 -2px 8px 2px rgba(0, 0, 0, 0.33);
    bottom: 0;
  }

  ul {
    font-size: 0.9em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 50px;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }
</style>
