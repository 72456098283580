<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Socials"
})
</script>

<template>
  <div class="socialContainer">
    <h1>Socials</h1>
    <table>
      <tr>
        <td>
          <img src="../../assets/Digital_Stacked_White.png" alt="WhatsApp Logo"/>
        </td>
        <td>
          <a href="https://wa.me/message/RQH5AVWZTTT5I1">Nachricht schicken</a>
        </td>
      </tr>
    </table>


  </div>
</template>

<style scoped lang="scss">

@import "@/assets/style/variablen";
@import
url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

h1, h2, h3, h4, h5, label {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  color: $element;
}
.socialContainer {
  display: flex;
  flex-direction: column;
  padding: 3em 1em 3em 2em;
  flex-grow: 1;
  height: 100%;
  max-width: 20em;
  background-color: $nav;
  border-radius: $border-radius-content;
  th, td {
    padding: 15px;
  }
  img {
    min-width: 4em;
    width: 4em;
  }
  h1 {
    font-size: 4em;
  }
  a {
    color: $element;
    text-decoration: none;
  }

}


@media screen and (max-width: 649px) {
  .socialContainer {
    h1 {
      font-size: 2.5em;
    }
  }
}
</style>