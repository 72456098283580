<script>

</script>

<template>
  <div>
    <div class="leistungen-header-container">
      <div class="leistungen-header-content">
        <h1>Unverwechselbare Marken für zukunftsorientierte Unternehmen</h1>
        <picture>
          <source srcset="../../assets/brands.webp" type="image/webp">
          <source srcset="../../assets/brands.jpg" type="image/jpeg">
          <img src="../../assets/brands.jpg" alt="unverwechselbar Marken">
        </picture>
      </div>
    </div>
    <div class="leistungen-main-container">
      <div class="leistungen-main-content">
        <div class="leistung leistung-1">
          <h3>Responsive Webdesign</h3>
          <p>Wir gewährleisten eine benutzerfreundliche Website auf allen Geräten. Mit dem "Mobile First" Prinzip
            sichern wir eine reibungslose Erfahrung.
          </p>
        </div>
        <div class="leistung leistung-2">
          <video autoplay muted playsinline loop id="leistung-2-video">
            <source src="../../assets/video/leistung-2.mp4" type="video/mp4">
          </video>
          <div class="leistung-2__content">
            <h3>Webentwicklung</h3>
            <p>Wir arbeiten partnerschaftlich mit Ihnen zusammen, um Ihre Ziele zu erreichen.
              Von der Anforderungsanalyse bis zur Veröffentlichung nutzen wir modernste Technik, um Ihre Wünsche
              umzusetzen.
              Auch nach der Veröffentlichung unterstützen wir Sie bei Wartung und Betrieb.
            </p></div>
        </div>
        <div class="leistung leistung-3">
          <h3>Programmierung</h3>
          <p>
            Wir beschränken uns nicht nur auf schöne Webseiten. Wir entwickeln für Sie mächtige Systeme,
             die Ihre Geschäftsprozesse optimal abbilden und so nicht nur Ihren Arbeitsalltag erleichtern,
            sondern auch die User Experience Ihrer Kunden spürbar steigert.
          </p>
        </div>
      </div>
    </div>
    <div class="leistungen-main-container">
      <div class="leistungen-main-content">
        <div class="leistung leistung-4">
          <div class="leistung-4__content">
            <h3>Datenbanken</h3>
            <p>Wir erstellen für Sie Datenbankensysteme, die perfekt auf das Management Ihrer Kunden sowie die
              Verwaltung Ihres Warenbestandes zugeschnitten sind. Sie können sich darauf verlassen, dass Ihre Daten
              sicher vor Verlust oder Angriffen von Außen geschützt sind.
              Ebenso sorgen wir für einen reibungslosen Betrieb auch bei Anwendungsfällen mit großen Datenmengen.
            </p>
          </div>
        </div>
        <div class="leistung leistung-5">
          <h3>Wartung und Support</h3>
          <p>
            Wir hören nicht bei der Entwicklung Ihres Webauftrittes auf, sondern garantieren Ihnen, dass Ihre
            Webseite zuverlässig
            arbeitet und Ihre Daten stets sicher verfügbar sind. Nach Absprache werden wir Ihnen eine oder mehrere
            der folgenden Services bereitstellen:
          </p>
          <ul>
            <li>
              Regelmäßige Datensicherung durch automatisierte Routinen
            </li>
            <li>
              Bereitstellung von Testsystemen und -Daten, damit Sie in aller Ruhe unsere Entwicklungen selbst
              ausprobieren können.
            </li>
            <li>
              Prüfung und Einspielung von Sicherheitspatches
            </li>
            <li>
              Gewährleistung ständiger Betriebsbereitschaft (97.5 % der Zeit) Ihrer Anwendungen
            </li>
            <li>
              Support per Telefon und E-Mail
            </li>
            <li>
              Regelmäßige Wartung der Anwendung, sowie Einspielen neuer Features
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  @import "@/assets/style/variablen";
  @import
  url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

  h1, h2, h3, h4, h5, p {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
  }

  .leistungen-header-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $content-bg;
    margin: 50px auto 0 auto;
    border: solid 1px $content-bg;
    .leistungen-header-content {
      position: relative;
      h1 {
        mix-blend-mode: luminosity;
        background-color: $element;
        position: absolute;
        top: 0;
        left: 0;
        color: black;
        text-align: center;
        font-size: 4em;
        z-index: 1;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }

  .leistungen-main-container {
    padding: 8em 2em 2em 2em;
    position: relative;
    background-color: $content-bg;
    margin: 0 auto;
    border: solid 1px $content-bg;
    .leistungen-main-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      h1 {
        position: absolute;
        top: 0;
        left: 0;
        color: black;
        text-align: center;
        font-size: 4em;
        z-index: 1;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }

  .leistung {
    display: flex;
    flex-direction: column;
    width: calc(30% - 1em);
    height: 35em;
    border-radius: $border-radius-content;
    h3 {
      font-size: 2em;
    }
    p {
      line-height: 1.2em;
      font-size: 0.9em;
      word-break: keep-all;
    }
  }
  .leistung-1 {
    background: url("../../assets/responsive.png") $nav 100% 100% no-repeat;
    background-size: contain;
    padding: 4em 1em 1em 1em;
    img {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: $border-radius-img;
    }
    h3 {
      font-size: 2em;
      color: $element;
    }
    p {
      text-align: left;
      width: 70%;
      padding-top: 4em;
    }
  }

  .leistung-2 {
    text-align: right;
    overflow: hidden;
    .leistung-2__content {
      background: url("../../assets/svg/organisation.svg") $body-bg no-repeat;
      background-size: contain;
      z-index: 1;
      height: 18em;
      h3 {
        text-align: right;
        padding: 1em 0.5em 1em 0;
        font-size: 2em;
        color: black;
        z-index: 20;
      }
      p {
        padding: 1em 1em;
        color: $nav;
        text-align: right;
        width: 100%;
      }
    }
    video {
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 0;
    }
  }

  .leistung-3 {
    background: url("../../assets/svg/code.svg") 100% 100% $element no-repeat;
    background-size: contain;
    padding: 4em 1em 1em 1em;
    color: $nav;
    h3 {
      color: black;
      font-size: 2em;
    }
    p {
      text-align: left;
      width: 70%;
      padding-top: 4em;
    }
  }

  .leistung-4 {
    background: url("../../assets/database.png") $nav 50% 95% no-repeat;
    background-size: 20em;
    padding: 4em 1em 1em 1em;
    .leistung-4__content {
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      h3 {
        color: $element;
      }
      p {
        text-align: left;
        width: 100%;
        padding-top: 2em;
      }
    }
    img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: $border-radius-img;
    }

  }

  .leistung-5 {
    background: url("../../assets/svg/support.svg") 50% 100% $element no-repeat;
    background-size: contain;
    padding: 4em 1em 1em 1em;
    color: $nav;
    section {
      display: flex;
      flex-direction: row;
    }
    p {
      padding-top: 2em;
      text-align: left;
      width: 80%;
    }
    li {
      font-size: 0.9em;
    }
  }

// Kleinere Bildschirme
@media screen and (min-width: 1024px) and (max-width: 1400px) {

  .leistungen-header-container {
    .leistungen-header-content {
      h1 {
        font-size: 2.5em;
      }
    }
  }

  .leistungen-main-container {
    padding: 2em 1em 2em 1em;
    .leistungen-main-content {
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }

  .leistung-1, .leistung-3, .leistung-2, .leistung-4, .leistung-5 {
    margin-top: 2em;
    width: 45%;

    h3 {
      font-size: 1.4em;
    }
  }

  .leistung-4 {
    background-position: 50% 95%;
    background-size: 20em;
    .leistung-4__content {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1em 1em 1em 1em;
      h3 {
        font-size: 2em;
        color: $element;
      }
      p {
        mix-blend-mode: difference;
        width: 100%;
        text-align: left;
        font-size: 0.8em;
        font-weight: bold;
      }
    }

  }
}

// Smartphone
@media screen and (min-width: 650px) and (max-width: 1023px) {

  .leistungen-header-container {
    .leistungen-header-content {
      overflow: hidden;
      h1 {
        font-size: 1.5em;
      }
      img {
        width: 150%;
      }
    }
  }

  .leistungen-main-content {
    flex-direction: column !important;
  }

  .leistung-1, .leistung-3, .leistung-2, .leistung-4, .leistung-5 {
    margin-top: 2em;
    height: 25em;
    width: 100%;
    h3 {
      font-size: 1.8em;
    }
    p {
      padding-top: 1em;
    }
  }

  .leistung-2 {
    .leistung-2__content {
      height: 14em;
      h3 {
        font-size: 1.6em;
      }
      p {
        font-size: 0.8em;
        padding-top: 0.8em;
      }
    }
  }

  .leistung-4 {
    background-position: 90% 90%;
    background-size: 10em;
    .leistung-4__content {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1em 1em 1em 1em;
      h3 {
        font-size: 2em;
        color: $element;
      }
      p {
        mix-blend-mode: difference;
        width: 60%;
        text-align: left;
        font-size: 0.8em;
        font-weight: bold;
      }
    }
  }

  .leistung-5 {
    background-size: contain;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 35em;
    border-radius: $border-radius-content;
    padding: 2em 1em 1em 1em;
    color: $nav;
    section {
      display: flex;
      flex-direction: row;
    }
    h3 {
      font-size: 2em;
    }
    p {
      padding-top: 2em;
      text-align: left;
      width: 80%;
      line-height: 1.2em;
      font-size: 1em;
      font-weight: bold;
    }
    li {
      font-size: 0.9em;
    }
  }

  .leistung-1 {
    background: url("../../assets/responsive.png") $nav 100% 100% no-repeat;
    background-size: 60%;
  }
}

@media screen and (max-width: 649px) {
  .leistungen-header-container {
    .leistungen-header-content {
      overflow: hidden;
      h1 {
        font-size: 1.5em;
      }
      img {
        width: 150%;
      }
    }
  }


  .leistungen-main-content {
    flex-direction: column !important;
  }

  .leistung-1, .leistung-3, .leistung-2, .leistung-4, .leistung-5 {
    margin-top: 2em;
    height: 25em;
    width: 100%;
    h3 {
      font-size: 1.6em;
    }
    p {
      padding-top: 1em;
    }
  }

  .leistung-2 {
    .leistung-2__content {
      height: 14em;
      h3 {
        font-size: 1.6em;
      }
      p {
        font-size: 0.8em;
        padding-top: 0.8em;
      }
    }
  }

  .leistung-4 {
    background-position: 90% 95%;
    background-size: 7em;
    padding-top: 0.2em !important;
    .leistung-4__content {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      padding: 0.5em;
      h3 {
        font-size: 1.6em;
        color: $element;
      }
      p {
        mix-blend-mode: difference;
        width: 100%;
        text-align: left;
        font-size: 0.8em;
        font-weight: unset;
      }
    }

  }
  .leistung-1 {
    background: url("../../assets/responsive.png") $nav 100% 100% no-repeat;
    background-size: 60%;
  }

  .leistung-5 {
    background-size: contain;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 45em;
    border-radius: $border-radius-content;
    padding: 2em 1em 1em 1em;
    color: $nav;
    section {
      display: flex;
      flex-direction: row;
    }
    h3 {
      font-size: 2em;
    }
    p {
      padding-top: 2em;
      text-align: left;
      width: 80%;
      line-height: 1.2em;
      font-size: 0.9em;
      font-weight: bold;
    }
    li {
      font-size: 0.8em;
    }
  }
}

</style>