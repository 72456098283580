<template>
  <nav>
    <ul>
      <router-link to="/">
        <li class="button-48">
        <span>
          Home
        </span>
        </li>
      </router-link>
      <router-link to="/about">
        <li class="button-48">
        <span>
          About
        </span>
        </li>
      </router-link>
      <router-link to="/leistungen">
        <li class="button-48">
        <span>
          Leistungen
        </span>
        </li>
      </router-link>
<!--      <router-link to="/projekte">
        <li class="button-48">
        <span>
          Projekte
        </span>
        </li>
      </router-link>-->
      <router-link to="/kontakt">
        <li class="button-48">
        <span>
          Kontakt
        </span>
        </li>
      </router-link>
    </ul>
  </nav>
</template>

<script>

</script>

<style lang="scss" scoped>
@import "@/assets/style/variablen";

nav {
  display: flex;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  height: 50px;
  font-size: 1.3em;
  @include transparentBackground($body-bg, 80%);
}

ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 50px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  color: $bright-font;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 160px;
  position: relative;
  list-style: none
}

.button-48 {
  appearance: none;
  border-width: 0;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: Clarkson,Helvetica,sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  outline: 0;
  padding: 1.5em 2.2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  transition: opacity 300ms cubic-bezier(.694, 0, 0.335, 1),background-color 100ms cubic-bezier(.694, 0, 0.335, 1),color 100ms cubic-bezier(.694, 0, 0.335, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  height: 50px;
}

.button-48:before {
  animation: opacityFallbackOut .5s step-end forwards;
  backface-visibility: hidden;
  @include transparentBackground($element, 80%);
  clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1), -webkit-clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1);
  width: 100%;
}

.button-48:hover:before {
  animation: opacityFallbackIn 0s step-start forwards;
  clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
}

.button-48:after {
  background-color: #FFFFFF;
}

.button-48 span {
  z-index: 1;
  position: relative;
}


// Für Tablets und ähnliche Geräte
@media screen and (min-width: 768px) and (max-width: 1024px) {
  li {
    width: 120px;
  }
}

// Für Smartphones
@media screen and (max-width: 767px) {

  .button-48 {
    font-size: .5em;
  }

  li {
    width: 120px;
  }
}

// sehr wenig Breite vorhanden
@media screen and (max-width: 480px) {

  .button-48 {
    font-size: .5em;
    padding: 1.9em 1em;
  }

  li {
    width: 100px;
  }
}

</style>