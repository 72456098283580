import 'bootstrap/dist/css/bootstrap.css';
import { createApp } from 'vue'
import App from './App.vue'
import bootstrap from 'bootstrap/dist/js/bootstrap'
import { MotionPlugin } from '@vueuse/motion'
import mitt from 'mitt';
import { VueHeadMixin, createHead } from '@unhead/vue'
import router from "@/router";

const emitter = mitt();
const app = createApp(App);
const head = createHead()

app.directive('hideelement', (el, binding) => {

        const setHide = binding.value
        if (setHide) {
                el.style.visibility = 'hidden';
        } else {
                el.style.visibility = 'visible'
        }

})

app.config.globalProperties.emitter = emitter

app
    .use(bootstrap)
    .use(MotionPlugin)
    .use(router)
    .use(head)
    .mixin(VueHeadMixin)
    .mount('#app');

