<template>
  <Navigation>
  </Navigation>
    <div class="content">
      <router-view v-slot="{ Component }">
        <Transition name="fade" mode="out-in">
            <component :is="Component"/>
        </Transition>
      </router-view>
    </div>
  <Footer>
  </Footer>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {

  data() {
    return {
      lang: 'de-DE',
    }
  },
  name: 'App',
  components: {Footer, Navigation},
}
</script>

<style lang="scss">

@import "@/assets/style/variablen";
@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import
url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}
body {
  margin: 0;
  padding: 0;
  background-color: $body-bg;
  height: 100%;
  width: 100%;
  font-size: 1.2em;
}

.content h5 {
  color: $bright-font;
}

.content > div > div {
  margin-bottom: 2em;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

::selection {
  color: black;
  background-color: $element;
}

</style>
