<template>
  <div>
    <video ref="videoPlayer" autoplay muted loop playsinline style="width:100%; height:100%;">
      Dieser Film zeigt eine Intro<br>
      Dein Browser kann dieses Video nicht wiedergeben.<br>
      Du kannst es <a :href="videoLink">Hier</a> abrufen.
    </video>
  </div>
</template>

<script>

export default {

  data() {
    return {
      videoLink: "@/assets/video/intro.mp4" // Setze den Standard-Link zur Intro-Datei
    }
  },

  mounted() {
    this.setVideoSource();
    window.addEventListener('resize', this.setVideoSource);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setVideoSource);
  },
  methods: {
    setVideoSource() {
      const videoPlayer = this.$refs.videoPlayer;
      const screenWidth = window.innerWidth;

      if (screenWidth <= 480) {
        videoPlayer.src = require("@/assets/video/intro-480.mp4");
      } else if (screenWidth <= 1280) {
        videoPlayer.src = require("@/assets/video/intro-1280.mp4");
      } else {
        videoPlayer.src = require("@/assets/video/intro.mp4");
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  @import "@/assets/style/variablen";

  .homeContainer {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    -webkit-filter: blur(1px);
    filter: blur(1px) brightness(80%);
  }

  .homeLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    z-index: 0;
    ::after {
      content: "";
      position: absolute;
    }
  }

  @media screen and (max-width: 450px) {
    .homeLayer {
      width: 90vw;
      height: 90vh;
    }
  }

  .headline,
  .description {
    position: relative;
    z-index: 1;
    padding: 4em 8em 4em 8em;
    color: $dark-font;
    h1 {
      margin: 0;
      color: $dark-font;
      span {
        color: black;
        background-color: $element;
      }
    }
  }

  .headline .description {
    position: relative;
    margin: 0;
    top: 100%;

  }


  ::selection {
    @include transparentBackground($element, 65%);
    color: black;
  }

  .image {
    @include transparentBackground(#000, 85%);
    display:  block;
    background: url("@/assets/svg/pattern.svg"), url("@/assets/kirch-head.webp") 50% 50% no-repeat;
    background-size: cover;
    width: 100%;
    height: 40vh;
    padding: 4em 2em 4em 8em;
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }

</style>