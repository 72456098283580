<template>
  <h1>Webdevelopment aus der Bergstraße</h1>
  <div class="werteFirstLineContainer">
    <div v-motion-roll-left  class="wert-1">
      <h3>Lösungen statt Ratschläge</h3>
      <p>
        Wir wollen Ihnen nicht erklären, wie Sie sich darstellen sollen. Wir entwickeln gemeinsam mit Ihnen den
        Webauftritt,
        von dem Sie schon immer geträumt haben.
      </p>
      <picture>
        <source srcset="../../assets/solution.webp" type="image/webp">
        <source srcset="../../assets/solution.jpg" type="image/jpeg">
        <img src="../../assets/solution.jpg" alt="Lösungen">
      </picture>
    </div>
    <div v-motion-fade-visible class="wert-2">
      <picture>
        <source srcset="../../assets/jung-dynamisch-1.webp" type="image/webp">
        <source srcset="../../assets/jung-dynamisch-1.jpg" type="image/jpeg">
        <img src="../../assets/jung-dynamisch-1.jpg" alt="Organisation">
      </picture>
      <section>
        <h3>Jung und Dynamisch</h3>
      </section>
      <p>In einer sich ständig ändernden Medienlandschaft aufgewachsen,
        überzeugen wir Sie von unserer Vision, das Vertrauen Ihrer Kunden online zu gewinnen.</p>
    </div>
    <div v-motion-roll-right class="wert-3">
      <h3>Organisation</h3>
      <p>In unserer Welt herrscht strukturierte Kreativität, nicht Chaos.
        Wir glauben an die Macht der Organisation, um klare Ziele zu definieren und gemeinsam darauf hinzuarbeiten.
      </p>
      <picture>
        <source srcset="../../assets/organisation.webp" type="image/webp">
        <source srcset="../../assets/organisation.jpg" type="image/jpeg">
        <img src="../../assets/organisation.jpg" alt="Organisation">
      </picture>
    </div>
  </div>
  <div class="werteSecondLineContainer">
    <div v-motion-pop-visible class="wert-4">
      <picture>
        <source srcset="../../assets/bindung.webp" type="image/webp">
        <source srcset="../../assets/bindung.jpg" type="image/jpeg">
        <img src="../../assets/bindung.webp" alt="Räumliche Bindung">
      </picture>
      <div class="content">
        <p>Aufgewachsen in der Bergstraße begleiten wir die Entwicklung der Region durch Erfolge und Krisen.
          Obwohl oft nicht gesehen, sind gerade kleine und mittelständische Unternehmen Impulsgeber für Innovation.
          Wir geben euch eine Stimme.
        </p>
      </div>
    </div>
    <div v-motion-pop-visible class="wert-5">
      <video autoplay muted playsinline loop id="wert-5-video">
        <source src="../../assets/video/wert-5.mp4" type="video/mp4">
      </video>
      <div class="content">
        <p>Wir wollen keine trendigen Produkte "verkaufen", sondern gemeinsam mit Ihnen etwas schaffen,
          in dem Sie die Hauptrolle spielen. Zusammen werden wir Ihre Innovation auch
          in der digitalen Welt überzeugend präsentieren.
        </p>
      </div>
    </div>
  </div>
</template>

<script>

</script>

<style lang="scss" scoped>

@import "@/assets/style/variablen";
@import
url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

  h1 {
    color: $nav;
    font-size: 3em;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    text-align: center;
    padding-bottom: 1em;
  }

  p, h3 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
  }

  .werteFirstLineContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .werteSecondLineContainer {
    margin-top: 8em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .wert-4 {
      position: relative;
      width: 50%;
      height: 35em;
      border-radius: $border-radius-content;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0; /* Hinter dem Inhalt */
      }
      .content {
        color: $nav;
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        @include transparentBackground($element, 50%);
      }
    }
    .wert-5{
      position: relative;
      width: 25%;
      height: 35em;
      border-radius: $border-radius-content;
      overflow: hidden;
      .content {
        color: $nav;
        font-weight: bold;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        @include transparentBackground($element, 50%);
      }
      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0; /* Hinter dem Inhalt */
      }
    }
  }

  .wert-1 {
    background: url("../../assets/svg/solution.svg") $nav no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 35em;
    border-radius: $border-radius-content;
    padding: 4em 1em 1em 1em;
    img {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: $border-radius-img;
    }
    h3 {
      font-size: 2em;
      color: $element;
    }
    p {
      text-align: left;
      width: 100%;
      line-height: 1.2em;
      font-size: 0.9em;
      word-break: keep-all;
      font-weight: bold;
    }
  }

  .wert-2 {
    background: url("../../assets/svg/dynamic.svg") 100% 100% $element no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 35em;
    border-radius: $border-radius-content;
    padding: 2em 1em 1em 1em;
    color: $nav;
    section {
      display: flex;
      flex-direction: row;
    }
    h3 {
      padding-top: 2em;
      font-size: 2em;
    }
    p {
      padding-top: 2em;
      text-align: left;
      width: 80%;
      line-height: 1.2em;
      font-size: 0.9em;
      font-weight: bold;
      word-break: keep-all;
    }
  }

  .wert-3 {
    background: url("../../assets/svg/organisation.svg") $body-bg no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 35em;
    border-radius: $border-radius-content;
    padding: 4em 1em 1em 1em;
    text-align: right;
    img {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: $border-radius-img;
    }
    h3 {
      font-size: 2em;
      color: $nav;
    }
    p {
      color: $nav;
      text-align: right;
      width: 100%;
      line-height: 1.2em;
      font-size: 0.9em;
      font-weight: bold;
      word-break: keep-all;
    }
  }

  .dynamicIcon {
    background: url("../../assets/svg/dynamic.svg") 100% 100% no-repeat;
    background-size: contain;
  }

  img {
    border-radius: $border-radius-img;
    width: 100%;
  }


// Kleinere Bildschirme
@media screen and (min-width: 1023px) and (max-width: 1600px) {

  .werteFirstLineContainer {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    justify-content: space-evenly;
  }
  .wert-1,
  .wert-2,
  .wert-3 {
    width: 35%;
    margin: 2em;
  }

  .werteSecondLineContainer {
    align-items: center;
    .wert-4 {
      width: 55%;
    }
    .wert-5 {
      width: 35%;
    }
  }

}

// Für Tablets und ähnliche Geräte
@media screen and (min-width: 768px) and (max-width: 1024px) {

  .werteFirstLineContainer {
    flex-direction: column;
    align-items: center;
  }
  .wert-1,
  .wert-2,
  .wert-3 {
    width: 80%;
    margin-bottom: 2em;
    p {
      padding-top: 1em;
    }
    h3 {
      padding-top: 0.5em;
      padding-bottom: 1em;
    }
  }

  .werteSecondLineContainer {
    flex-direction: column;
    align-items: center;
    .wert-4,
    .wert-5 {
      margin-top: 4em;
      width: 80%;
    }
  }

}

// Für Smartphones
@media screen and (max-width: 767px) {

  h1 {
    font-size: 1.5em;
  }

  .wert-1, .wert-2, .wert-3, .wert-4, .wert-5 {
    height: 30em;
  }

  .werteFirstLineContainer {
    flex-direction: column;
    align-items: center;
  }
  .wert-1,
  .wert-2,
  .wert-3 {
    padding: 1em 1em 1em 1em;
    width: 90%;
    margin-bottom: 2em;
    text-align: center;
    img {
      width: 90%;
    }

    p {
      padding-top: 0.5em;
    }
    h3 {
      padding-top: 0em;
      padding-bottom: 0em;
    }

  }

  .werteSecondLineContainer {
    margin-top: 4em;
    flex-direction: column;
    align-items: center;
    .wert-4,
    .wert-5 {
      height: 30em;
      width: 90%;
      margin-bottom: 2em;
      img {
        object-fit: cover;
      }
      .content {
        font-size: 0.8em;
      }
    }
  }
}

</style>
