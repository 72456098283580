<template>
  <div class="aboutContainer">
    <Werte>
    </Werte>
  </div>
</template>

<script>
  import Werte from "@/components/about/Werte.vue";

  export default {
    components: {Werte},
  }

</script>

<style lang="scss" scoped>

@import "@/assets/style/variablen";

  .aboutContainer {
    background-color: $content-bg;
    margin-top: 50px;
    padding: 1em 1em !important;
  }

</style>