import {createRouter, createWebHistory} from 'vue-router';
import Home from "@/components/Home.vue";
import About from "@/components/about/About.vue";
import Projekte from "@/components/Projekte.vue";
import Leistungen from "@/components/leistungen/Leistungen.vue";
import Kontakt from "@/components/kontakt/Kontakt.vue";
import Impressum from "@/components/Impressum.vue";
import LandingPage from "@/components/kontakt/LandingPage.vue";

const routes = [
    { path: '/', component: Home },
    { path: '/about', component: About },
    { path: '/projekte', component: Projekte},
    { path: '/leistungen', component: Leistungen},
    { path: '/kontakt', component: Kontakt},
    { path: '/impressum', component: Impressum},
    {
        path: '/landingpage/:code/:message',
        component: LandingPage,
        name: 'LandingPage',
        props: true,
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes,
    // scrollt immer nach oben beim Seitenwechsel
    scrollBehavior() {
      return { top: 0 }
    },
})

export default router