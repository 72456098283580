<template>
  <div class="kontakt-container">
    <component :is="currentComponent" @load-landing-page="loadLandingPage">
    </component>
    <Socials>
    </Socials>
  </div>
</template>

<script>

import Formular from "@/components/kontakt/Formular.vue";
import {computed} from "vue";
import Socials from "@/components/kontakt/Socials.vue";

export default {

  components: {Socials, Formular},

  data() {
    return {
      containerHeight: '80% !important', // Standardhöhe
      marginBottom: '2em',
      currentComponent: 'Formular',
      statusCode: '',
      statusMessage: '',
    }
  },

  provide() {
    return {
      statusCode: computed(() => this.statusCode),
      statusMessage: computed(() => this.statusMessage),
    }
  },

  methods: {
    loadLandingPage(statusCode, statusMessage) {
      this.statusCode = statusCode
      this.statusMessage = statusMessage
      this.currentComponent = 'LandingPage';
    },
  },


}

</script>

<style lang="scss" scoped>

@import "@/assets/style/variablen";

.kontakt-container {
  background-color: $content-bg;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px auto 0 auto;
  padding: 2em 0 2em 0;
}

</style>