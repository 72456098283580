<template>
  <div class="formular-container">
    <div class="formular-form">
      <h1>Kontakt</h1>
      <form @submit.prevent="submitForm" ref="kontakt">
        <div class="mb-3">
          <label for="contact_name" class="form-label">Name</label>
          <input
              class="form-control"
              :class="{}"
              id="contact_name"
              v-model="formData.contact_name"
              :ref="(el) => validateInput(el)"
              required type="text"
              inputmode="text"
              autocomplete="name"/>
        </div>
        <div class="mb-3">
          <label class="form-label" for="company">Firma</label>
          <input
              id="company"
              class="form-control"
              v-model="formData.company_name"
              required type="text"
              inputmode="text"
              autocomplete="company"
          ></div>
        <div class="mb-3">
          <label class="form-label" for="email">E-Mail Adresse</label>
          <label class="form-label invalidTel" v-show="!validEmail">Ungültige E-Mail-Adresse</label>
          <input
              id="email"
              :ref="(el) => validateInput(el)"
              class="form-control"
              v-model="formData.email"
              :class="{invalidInput: !validEmail && formData.email}" required type="email">
        </div>
        <div class="mb-3">
          <label class="form-label" for="call_nr">Telefonnummer</label>
          <label class="form-label invalidTel" v-show="!validTel">Ungültige Telefonnummer</label>
          <input
              id="call_nr"
              class="form-control"
              v-model="formData.call_nr"
              required type="tel"
              :class="{invalidInput: !validTel}">
        </div>
        <div class="mb-3">
          <label class="form-label" for="subject">Betreff</label>
          <input
              id="subject"
              :ref="(el) => validateInput(el)"
              class="form-control"
              v-model="formData.subject"
              required type="text"
          ></div>
        <div class="mb-3">
          <label class="form-label" for="message">Nachricht</label>
          <textarea
              id="message"
              :ref="(el) => validateInput(el)"
              class="form-control messageBox"
              v-model="formData.message"
              required type="text"/>
        </div>
        <div class="checkBox"><input type="checkbox" id="privacyCheck" v-model="isPrivacyChecked">
          <label class="checkBoxLabel">Ich erkläre mich damit einverstanden, dass die Informationen,
            die ich im Kontaktformular angebe, zur Beantwortung meiner Anfrage erfasst und gespeichert werden.
            Zudem stimme ich der Verarbeitung meiner Daten gemäß der
            <RouterLink role="button" class="link" to="/impressum">Datenschutzerklärung</RouterLink>
            zu.
          </label>
        </div>
      </form>
      <div class="submit">
        <p v-hideelement="formComplete && validEmail">Bitte fülle alle erforderlichen Felder aus</p>
        <button
            type="submit"
            class="btn"
            @click="submitForm"
            :class="{buttonDisabled: !formComplete || !validEmail || !validTel}"
            :disabled="!(formComplete && validEmail)">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {parsePhoneNumber} from "libphonenumber-js";
import axios from "axios";
import router from "@/router";

export default {

  data() {
    return {
      formData: this.resetFormData(),
      currentComponent: null,
      isInitName: false,
      isInitEmail: false,
      isInitSubject: false,
      isInitMessage: false,
      isPrivacyChecked: false,
    }
  },

  computed: {

    validEmail: function() {
        if (this.formData.email === '') {
          return true
        } else {
          return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.formData.email);
        }
    },

    formComplete: function() {
      return (this.formData.email && this.formData.contact_name && this.formData.subject && this.formData.message &&
          this.isPrivacyChecked);
    },

    validTel: function () {
      try {
        const phoneNumber = parsePhoneNumber(this.formData.call_nr.toString(), 'DE');
        if (!phoneNumber) {
          return false
        } else {
          return true
        }
      } catch (NOT_A_NUMBER) {
        if (this.formData.call_nr.toString() === '') {
          return true
        } else {
          return false
        }
      }
    },
  },

  methods: {

    resetFormData() {
      this.isInitEmail = false;
      this.isInitName = false;
      this.isInitSubject = false;
      this.isInitMessage = false;

      return {
        email: '',
        contact_name: '',
        company_name: '',
        subject: '',
        message: '',
        call_nr: '',
      };
    },

    async submitForm() {
      axios.post('/send-email', this.formData)
          .then(response => {
            if (response.status === 200) {
              //this.$emit('loadLandingPage', 200, 'Deine Nachricht wurde erfolgreich versendet');
              //this.$refs.kontakt.reset();
              //event.target.reset();
              Object.assign(this.formData, this.resetFormData());
              router.push({
                name: 'LandingPage', params: {
                  code: 200,
                  message: 'Deine Nachricht wurde erfolgreich versendet'
                }
              })
            }
          })
          .catch(error => {
            // TODO: Fehlermeldungen erweitern.
            if (error.response.status === 406) {
              router.push(
                  {
                    name: 'LandingPage', params: {
                      code: 406,
                      message: 'Bitte kein Spam'
                    }
                  }
              )
            } else {
              console.error(error);
            }
          });

    },

    validateInput(element) {
      if (element == null) {
        return;
      }
      switch (element.id) {
        case 'contact_name':
          this.validateField(element, 'isInitName');
          break;
        case 'subject':
          this.validateField(element, 'isInitSubject');
          break;
        case 'message':
          this.validateField(element, 'isInitMessage');
          console.log(element.value)
          break;
        case 'email':
          this.validateField(element, 'isInitEmail');
          break;
      }
    },

    validateField(element, initFlag) {
      if (element.value !== '') {
        this[initFlag] = true;
        element.classList.remove('invalidInput');
      } else {
        if (this[initFlag]) {
          element.classList.add('invalidInput');
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>

@import "@/assets/style/variablen";
@import
url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

h1, h2, h3, h4, h5, label {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  color: $element;
}

.formular-container {
  display: flex;
  width: 70%;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin: 0 0 2em 0;
}

.formular-form {
  background-color: $nav;
  border-radius: $border-radius-content;
  width: 80%;
  padding: 3em;
  h1 {
    font-size: 4em;
  }
}

.submit {
  padding-top: 2em;
}

.invalidTel {
  margin-left: 1.2em;
  color: #ff7171;
}

.btn {
  box-shadow: 2px 2px 8px 0 rgba(27, 38, 43, 0.8);
  background: $bright-font url("../../assets/svg/send.svg") no-repeat 50% 50% !important;
  height: 40px;
  width: auto;
  padding-left: 2em;
  padding-right: 2em;
  border-radius: 5px;
  border: none;
}

.messageBox {
  height: 13rem;
  overflow-y: scroll;
  resize: none;
}

.messageBox:focus {
  box-shadow: $element 0 0 3px 3px;
}

.invalidInput {
  border: 1px solid #ff7171;
  box-shadow: #ff7171 0 0 3px 3px;
}

.invalidInput:focus {
  border: 1px solid #ff7171;
  box-shadow: #ff7171 0 0 3px 3px;
}

.form-control:focus {
  border: 1px solid $element;
}

input:focus {
  box-shadow: $element 0 0 3px 3px;
}
.buttonDisabled {
  cursor: not-allowed;
}

@media screen and (min-width: 650px) and (max-width: 1024px){

  .formular-container {
    width: calc($viewport-width);
    background-color: $content-bg;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    padding: 1em;
  }

  .formular-form {
    width: 80%;
    padding: 2em;
    h1 {
      font-size: 2.5em;
    }
  }
}



@media screen and (max-width: 649px) {
  .formular-container {
    width: calc($viewport-width);
    background-color: $content-bg;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin: auto;
    padding-bottom: 1em;
  }

  .formular-form {
    background-color: $nav;
    border-radius: $border-radius-content;
    width: 100%;
    padding: 2em;
    h1 {
      font-size: 2.5em;
    }
  }
}
</style>