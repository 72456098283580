<template>
  <div class="impressum-container">
    <div class="impressum-content">
      <h1>
      Impressum
    </h1>
      <h2>
        Redaktionell Verantwortlicher:
      </h2>
      <p>
        Sebastian Adrian
        <br>
        Mozartstraße 5
        <br>
        64625 Bensheim
        <br>
        <b>
        Kontakt: mail@sadrian.eu
        <br>
        </b></p>
      <br/>
      <h2>Umsatzsteuer-Identifikationsnummer
        <br>gemäß § 27 a Umsatzsteuergesetz:</h2>
      <p><b>DE368274361</b></p>
      <h2>EU-Streitschlichtung </h2>
      <p>Die Europäische Kommission stellt eine Plattform zur <a href="https://ec.europa.eu/consumers/odr/">Online-Streitbeilegung (OS)</a> bereit: 
        <br>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
      <h2>Schlichtungsstelle</h2>
      <p> Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen. </p>
      <h2>
        Datenschutz:
      </h2>
      <h3>
        Allgemeine Hinweise
      </h3>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert,
        wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert
        werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
        aufgeführten Datenschutzerklärung.
      </p>
      <h3>
        SSL- bzw. TLS-Verschlüsselung
      </h3>
      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel
        Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung.
        Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf
        „https://“
        wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist,
        können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h3>
        Externes Hosting
      </h3>
      <p>
        Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf
        dieser
        Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um
        IP-Adressen,
        Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
        Daten, die über eine Website generiert werden, handeln.
      </p>
      <p>
        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potentiellen und bestehenden
        Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung
        unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine
        entsprechende
        Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
        DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
        Informationen
        im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit
        widerrufbar.
        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten
        erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.
      </p>
      <p>
        Wir setzen folgenden Hoster ein:
      </p>
      <p>
        STRATO AG
        <br>
        Otto-Ostrowski-Straße 7,
        <br>
        10249 Berlin
        <br>
        Telefon: 030-300 146 0
        <br>
        E-Mail-Kontakt: impressum@strato.de
      </p>
      <h3>
        Server-Log-Dateien
      </h3>
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die
        Ihr
        Browser automatisch an uns übermittelt. Dies sind:
      </p>
      <ul>
        <li>
          Browsertyp und Browserversion
        </li>
        <li>
          verwendetes Betriebssystem
        </li>
        <li>
          Referrer URL
        </li>
        <li>
          Hostname des zugreifenden Rechners
        </li>
        <li>
          Uhrzeit der Serveranfrage
        </li>
        <li>
          IP-Adresse
        </li>
      </ul>
      <p>
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
        Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
        berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu
        müssen die Server-Log-Files erfasst werden.
      </p>
      <h3>
        Kontaktformular
      </h3>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive
        der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen
        bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.

        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der
        Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist.
        In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung
        der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
        DSGVO)
        sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.

        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern,
        Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
        (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
        insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <h3>
        Anfrage per E-Mail, Telefon oder Telefax
      </h3>
      <p>
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
        hervorgehenden
        personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und
        verarbeitet.
        Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der
        Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In
        allen
        übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an
        uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
        sofern
        diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
      </p>
      <p>
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
        auffordern,
        Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
        (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
        Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <p>
        Nachfolgend informieren wir Sie über die Verarbeitung Ihrer Daten im Zusammenhang mit der Nutzung von Matomo
        Analytics auf unserer Website gemäß den geltenden deutschen Datenschutzbestimmungen und der
        Datenschutz-Grundverordnung (DSGVO).
      </p>
      <h4>Verantwortliche Stelle</h4>
      <p>
        Verantwortlich für die Verarbeitung Ihrer Daten im Zusammenhang mit der Nutzung von Matomo Analytics
        auf dieser Website ist:
      </p>
      <p>
        Sebastian Adrian
        <br>
        Mozartstraße 5
        <br>
        64625 Bensheim
        <br>
        Kontakt: mail@sadrian.eu
        <br>
      </p>
      <h3>Verarbeitung personenbezogener Daten</h3>
      <p>
        Matomo Analytics ermöglicht es uns, statistische Auswertungen über die Nutzung unserer Website zu erstellen,
        um diese zu optimieren und benutzerfreundlicher zu gestalten. Dabei werden keine personenbezogenen Daten von
        Ihnen
        erhoben, da wir Matomo Analytics datenschutzkonform einsetzen und die IP-Adressen unserer Website-Besucher
        anonymisieren.
      </p>
      <h4>Zweck der Datenverarbeitung</h4>
      <p>
        Die Verarbeitung Ihrer Daten durch Matomo Analytics erfolgt ausschließlich zu statistischen Zwecken,
        um das Nutzerverhalten auf unserer Website zu analysieren und unsere Website entsprechend zu verbessern.
        Die erhobenen Daten dienen ausschließlich internen Auswertungszwecken und werden nicht an Dritte weitergegeben.
      </p>
      <h4>Rechtsgrundlage der Datenverarbeitung</h4>
      <p>
        Die Rechtsgrundlage für die Verarbeitung Ihrer Daten im Zusammenhang mit der Nutzung von Matomo Analytics beruht
        auf Art. 6 Abs. 1 lit. f DSGVO. Unser berechtigtes Interesse besteht darin, das Nutzerverhalten auf unserer
        Website zu analysieren und unsere Website entsprechend zu optimieren.
      </p>
      <h4>Speicherdauer</h4>
      <p>
        Die durch Matomo Analytics erhobenen Daten werden nach der Analyse für einen Zeitraum von
        [Speicherdauer angeben, z.B. 12 Monate] gespeichert und anschließend gelöscht.
      </p>
      <h4>Ihre Rechte</h4>
      <p>
        Als betroffene Person haben Sie das Recht auf Auskunft über die Verarbeitung Ihrer Daten sowie auf Berichtigung,
        Löschung oder Einschränkung der Verarbeitung Ihrer Daten. Darüber hinaus haben Sie das Recht, der Verarbeitung
        Ihrer Daten zu widersprechen sowie das Recht auf Datenübertragbarkeit. Bitte kontaktieren Sie uns dazu unter den
        in Abschnitt 1 angegebenen Kontaktdaten.
      </p>
      <h4>Beschwerderecht</h4>
      <p>
        Sie haben das Recht, sich bei der zuständigen Datenschutzbehörde über die Verarbeitung Ihrer Daten zu
        beschweren.
        Die für uns zuständige Datenschutzbehörde ist:
      </p>
      <p>
        <br>
        Der Hessische Beauftragte für Datenschutz und Informationsfreiheit
        <br>
        vertreten durch
        <br>
        Prof. Dr. Alexander Roßnagel
        <br>
        Gustav-Stresemann-Ring 1
        <br>
        65189 Wiesbaden
        <br>
      </p>
      <h2>Änderungen dieser Datenschutzerklärung</h2>
      <p>
        Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern.
        Die jeweils aktuelle Version finden Sie auf unserer Website.
      </p>
      <h3>Stand: 28.06.2024</h3>
      <p>
        Bitte beachten Sie auch unsere allgemeine Datenschutzerklärung für weitere Informationen zur Verarbeitung Ihrer
        Daten auf unserer Website.
        Für Fragen oder weitere Informationen zur Verwendung von Matomo Analytics stehen wir Ihnen gerne zur Verfügung.
      </p></div>
  </div>
</template>

<script>



</script>

<style scoped lang="scss">

@import "@/assets/style/variablen";

  .impressum-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-content;
    padding: 2em;
    .impressum-content {
      width: 50%;
    }
    h1 {
      background-color: $element;
      color: black;
    }
    h2 {
      color: adjust-text-color($bright-font, -10%)
    }
    h3 {
      color: adjust-text-color($bright-font, 0%)
    }
    h4 {
      color: adjust-text-color($bright-font, 5%)
    }
    p {
      color: $dark-font;
    }
    li {
      color: $bright-font;
    }
  }

// Für Tablets und ähnliche Geräte
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .impressum-container {
    .impressum-content {
      width: 75%;
    }
  }

}

// Für Smartphones
@media screen and (max-width: 767px) {
  .impressum-container {
    .impressum-content {
      width: 98%;
    }
  }
}



</style>